import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { DEFAULT_OPTIONS } from './';
export default defineComponent({
    name: 'CardStory',
    props: {
        data: {
            default: () => [],
            type: Array,
        },
        options: {
            default: () => DEFAULT_OPTIONS,
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const currentOptions = computed(() => {
            return {
                ...DEFAULT_OPTIONS,
                ...props.options,
            };
        });
        const entries = ['Сюжет', ...currentOptions.value.entries];
        const title = computed(() => {
            let attrs = {};
            let component = 'div';
            if (currentOptions.value.moreTo) {
                attrs = {
                    to: currentOptions.value.moreTo,
                };
                component = 'NLink';
            }
            return {
                attrs,
                component,
            };
        });
        return {
            css,
            entries,
            currentOptions,
            title,
        };
    },
});
