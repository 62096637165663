// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l9n6c{position:relative}@media screen and (min-width:768px){.l9n6c{display:flex;height:28px;align-items:center}}.uhnD3{display:flex;flex-flow:column;justify-content:space-between;color:#989898;font-size:13px;grid-gap:12px;gap:12px;line-height:18px;text-decoration:none;transition:color .2s}@media screen and (min-width:375px){.uhnD3{flex-flow:row;align-items:center}}@media screen and (min-width:768px){.uhnD3{flex-flow:row-reverse;font-size:13px;grid-gap:16px;gap:16px;line-height:16px}}.us6ma .uhnD3{flex-flow:row;align-items:center}@media screen and (min-width:768px){.us6ma .uhnD3{flex-flow:row-reverse}}._6o7Dy{display:block;flex:0 0 auto}.l9n6c .tz12p{max-width:180px;height:40px}@media screen and (min-width:768px){.l9n6c .tz12p{max-width:128px;height:28px}}.l9n6c._9t53x .tz12p{height:20px}@media screen and (min-width:768px){.l9n6c._9t53x .tz12p{height:14px}}.l9n6c._1dc-j .tz12p{height:32px}@media screen and (min-width:768px){.l9n6c._1dc-j .tz12p{height:20px}}.l9n6c .tz12p:after{content:none}.l9n6c .tz12p img:first-child{position:unset;-o-object-fit:contain;object-fit:contain;-o-object-position:left;object-position:left}.l9n6c .tz12p img:last-child{display:none}.VMlGN{display:flex;flex-flow:column;align-items:flex-end;grid-gap:2px;gap:2px}.VMlGN.ZMGMX{flex-flow:row;align-items:center;grid-gap:4px;gap:4px}.Vzo2t{color:#989898}.Vzo2t>*{grid-gap:8px;gap:8px}.bGbG8{position:absolute;z-index:1;top:0;left:0;display:flex;width:100%;height:40px;flex:0 0 auto;align-items:center;animation:Psx3a 1s linear infinite reverse;background-image:linear-gradient(90deg,#2d2d2d 8%,#181716 38%,#2d2d2d 54%);background-size:200% auto;border-radius:4px}@media screen and (min-width:768px){.bGbG8{height:28px}}@keyframes Psx3a{0%{background-position:-100% 0}to{background-position:100% 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "l9n6c",
	"card": "uhnD3",
	"logoType1": "us6ma",
	"imageLink": "_6o7Dy",
	"image": "tz12p",
	"logoType2": "_9t53x",
	"logoType3": "_1dc-j",
	"content": "VMlGN",
	"isInline": "ZMGMX",
	"advertiser": "Vzo2t",
	"loader": "bGbG8",
	"card-story-adfox-loader": "Psx3a"
};
module.exports = ___CSS_LOADER_EXPORT___;
