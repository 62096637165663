import { computed, defineComponent, ref, useContext, useCssModule } from '@nuxtjs/composition-api';
import { ADFOX_CODES_DESKTOP, ADFOX_FETCH_STATES } from '~/components/Adfox';
import { parseAdfoxHTML } from '~/utils';
import { SHOULD_DISABLE } from '~/plugins/adfox';
export default defineComponent({
    name: 'CardStoryAdfox',
    setup() {
        /**
         * Composables.
         */
        const css = useCssModule();
        const context = useContext();
        const viewport = context.$viewport;
        /**
         * Adfox config
         */
        const config = ADFOX_CODES_DESKTOP.logo_story;
        /**
         * Root element
         */
        const rootEl = ref();
        /**
         * Adfox pseudo-article
         */
        const adfoxArticle = ref();
        const shouldRender = ref(!SHOULD_DISABLE);
        const currentStates = ref({ ...ADFOX_FETCH_STATES });
        const logoType = ref(1);
        const isInline = computed(() => {
            if (logoType.value > 1) {
                return !viewport.matches('mobileMedium', 'mobileWide');
            }
            return viewport.isGreaterThan('mobileWide');
        });
        /**
         * Adfox onComplete
         */
        function onComplete({ state }) {
            currentStates.value = state;
            if (rootEl.value) {
                const adfoxEl = rootEl.value.querySelector('[id^="adfox"]');
                if (!adfoxEl) {
                    return;
                }
                const hasError = state.withError || state.withStub;
                if (!hasError) {
                    // Parse HTMl in Adfox container
                    adfoxArticle.value = parseAdfoxHTML(adfoxEl);
                    // Slice title
                    if (adfoxArticle.value?.title) {
                        adfoxArticle.value.title = adfoxArticle.value.title.slice(0, 25);
                    }
                    // Detect image aspect ratio
                    if (adfoxArticle.value?.photo?.uri) {
                        const img = new Image();
                        img.src = adfoxArticle.value.photo.uri;
                        img.onload = function () {
                            const aspectRatio = img.width / img.height;
                            if (aspectRatio <= 2) {
                                logoType.value = 1;
                            }
                            else if (aspectRatio > 2 && aspectRatio < 5.5) {
                                logoType.value = 2;
                            }
                            else if (aspectRatio >= 5.5) {
                                logoType.value = 3;
                            }
                        };
                    }
                }
                shouldRender.value = !hasError;
            }
        }
        return {
            adfoxArticle,
            config,
            css,
            currentStates,
            onComplete,
            rootEl,
            shouldRender,
            logoType,
            isInline,
        };
    },
});
