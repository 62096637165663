// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+wwB8{position:relative;width:100%;min-height:294px;background:#181716;border-radius:8px;transition:background .3s;container-type:inline-size}@media screen and (min-width:1236px){.\\+wwB8{background-size:460px 184px}}.P7-1d{display:block;padding:0 16px;margin:0 0 40px;color:#fff;font-family:\"Merriweather\",serif;font-size:36px;font-weight:700;line-height:40px}@media screen and (min-width:1024px){.P7-1d{margin-bottom:48px;font-size:52px;line-height:56px}@container (max-width: 616px){.P7-1d{font-size:36px;line-height:40px}}}a.P7-1d{transition:color .2s}a.P7-1d:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#989898}@media(any-hover:hover){a.P7-1d:hover{color:#989898}}._5PAXq{flex:0 0 auto;color:#989898;font-size:13px;line-height:20px}.T6l7M{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "+wwB8",
	"title": "P7-1d",
	"addon": "_5PAXq",
	"loader": "T6l7M"
};
module.exports = ___CSS_LOADER_EXPORT___;
