import { DEFAULT_OPTIONS as SLIDER_OPTIONS } from '~/components/Slider';
export const DEFAULT_OPTIONS = {
    ...SLIDER_OPTIONS,
    entries: [],
    entryTheme: 'gray',
    itemGap: '32px',
    itemWidth: '268px',
    theme: 'dark',
    toolbarPosition: 'bottom',
    title: '',
    type: 'story',
    withAd: false,
};
